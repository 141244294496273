/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";


.main-container {
  padding: 10px;
  width: 100%;
  padding-top: 0px;
}

.container-fluid {
  padding-left: 50px;
}

.right-header {
  z-index: 9999;
  padding-top: 10px;
  padding-bottom: 5px;
}

.right-content {
  height: calc(100vh - 75px);
  overflow: auto;
  padding-top: 10px;

}

.main-container {
  background: #fff;
}

.tab-main {
  padding-left: 57px;
  padding-right: 57px;
}

.tabs {
  overflow: hidden;
  background-color: #fff;
  min-height: 600px;
}

.tab-buttons {
  border-bottom: 3px solid rgb(203, 154, 87);
}

.tab-button {
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 14px;
  min-width: 150px;
  font-weight: bold;
  transition: background-color 0.3s;
  border-radius: 8px 8px 0px 0px;
  color: rgb(69, 93, 153);
}

.tab-button:hover {
  background-color: #ddd;
}

.tab-button.active {
  background-color: white;
  color: rgb(69, 93, 153);
  border: 1px solid rgb(203, 154, 87);
  border-bottom: 0px;
}

.separator {
  width: 1px;
  height: 20px;
  /* Adjust height as needed */
  background-color: #ccc;
  /* Color of the separator */
  margin: 0 10px;
  /* Space around the separator */
}

.tab-content {
  text-align: right;
  /*border: 1px solid rgb(203, 154, 87);*/
}

.tab-search-box {
  padding: 10px 0px 10px 0px;
  width: 30%;
  float: right;
}

.tab-search-box input {
  width: 100%;
}

.text-container {
  overflow-wrap: break-word;
  /* Break long words if necessary */
  word-wrap: break-word;
  /* For compatibility with older browsers */
  white-space: normal;
  /* Allow text to wrap */
  float: left;
}

.setting-name {
  width: 25%;
  /* Set a fixed width */
  padding: 4px;
}

.setting-value {
  width: 93%;
}

.setting-button {
  width: 7%;
  /* Set a fixed width */
  text-align: right;
}

.wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 960px;
}

.error-input {
  border-style: solid;
  border-color: #dc3545;
}

.btn-edit {
  font-size: 19px;
  color: rgb(69, 93, 153);
}

.input-container {
  position: relative;
  display: inline-block;
}

table {
  border: 2px solid gray;
  width: 100%;
  border-collapse: separate !important;
  border-radius: 10px;
}

th {
  color: rgb(69, 93, 153);
  padding-top: 20px !important;
}

th,
td {
  border: 1px solid #ddd;
  padding: 5px 5px 5px 18px;
  text-align: left;
}

input {
  width: 80%;
}

/* Alternate row styling */
tbody tr:nth-child(even) {
  background-color: #f2f2f2;
  /* Light gray for even rows */
}

tbody tr:nth-child(odd) {
  background-color: #ffffff;
  /* White for odd rows */
}

/* mms-settings.component.css */
.spinner {
  padding: 9px;
  padding: 6px;
  border: 20px solid rgb(203, 154, 87);
  border-top: 20px solid rgb(69, 93, 153);
  border-radius: 50%;
  width: 1px;
  height: 0px;
  animation: spin 1s linear infinite;
  float: right;
  margin-left: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.container {
  position: relative;
  width: 300px;
  height: 200px;
  border: 2px solid #3498db;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
}

.loader {
  border: 8px solid rgb(69, 93, 153);
  border-top: 8px solid rgb(203, 154, 87);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: spin 1s linear infinite;
  z-index: 1000;
}

.btn-add-new {
  background: rgb(69, 93, 153) !important;
  color: #fff !important;
}